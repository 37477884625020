import React, { useState } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { useForm } from 'react-hook-form'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'

import SplitLayout from '../../components/common/splitLayout'
import Seo from '../../components/common/seo'
import media from '../../components/css/media'
import Error from '../../components/common/errorMessage'
import Success from '../../components/common/successMessage'
import { EnvConfig } from '../../config/EnvConfig'
import { doForgotPassword } from '../../adapters/auth'
import useDeviceDetect from '../../hooks/useDeviceDetect'

const Content = styled.div`
  position: relative;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  form {
    max-width: 80%;
    width: 100%;
    margin: 0 auto;
  }

  ${media.desktop`
    form {
      max-width: 500px;
    }
  `};
`

const ForgotPassword = () => {
  const { isMobile } = useDeviceDetect()
  const intl = useIntl()

  const data = useStaticQuery(graphql`
    {
      otherBannersApi {
        values {
          lang
          otherBanners {
            background
            backgroundMobile
            banner_title_text
            text_color
            type
          }
        }
      }
      customSlugsApi {
        values {
          lang
          slugs {
            generic_route
            language_route
          }
        }
      }
    }
  `)

  const {
    otherBannersApi: { values },
    customSlugsApi: { values: customSlugValues },
  } = data

  const bannerExistForLocale = values.some((row) => row.lang === intl.locale)
  const bannerExistForPage =
    bannerExistForLocale &&
    values
      .filter((row) => row.lang === intl.locale)[0]
      .otherBanners.some((i) => i.type === 'login')
  const banner = bannerExistForPage
    ? values
        .filter((row) => row.lang === intl.locale)[0]
        .otherBanners.filter((i) => i.type === 'login')[0]
    : null

  const slugsExistForLocale = customSlugValues.some(
    (row) => row.lang === intl.locale
  )
  const slugsExistForPage =
    slugsExistForLocale &&
    customSlugValues.filter((row) => row.lang === intl.locale)[0].slugs
  const slugs = slugsExistForPage
    ? customSlugValues.filter((row) => row.lang === intl.locale)[0].slugs
    : []

  const loginSlug = slugs.some(
    (slug) => slug.generic_route.toLowerCase() === 'login'
  )
    ? slugs.filter((slug) => slug.generic_route.toLowerCase() === 'login')[0]
        .language_route
    : 'login'

  const image = banner ? banner.background : null
  const imageMobile = banner ? banner.backgroundMobile : null
  const text = banner ? banner.banner_title_text : null
  const textColor = banner ? banner.text_color : null

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = useForm()

  const [response, setResponse] = useState(null)

  const onSubmit = async (data) => {
    const returnUrl = `${EnvConfig.SITE_URL}/${intl.locale}/reset/`
    const response = await doForgotPassword(data.email, returnUrl)
    setResponse(response)

    if (response.ok)
      setTimeout(() => navigate(`/${intl.locale}/${loginSlug}/`), 3000)
  }

  return (
    <SplitLayout
      img={isMobile ? imageMobile : image}
      text={text}
      textColor={textColor}
      hideLeftSideOnMobile={true}
    >
      <Seo
        title={intl.formatMessage({ id: `seo.forgot_password_title` })}
        description={intl.formatMessage({
          id: `seo.forgot_password_description`,
        })}
      />

      <Content>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h1>
            <FormattedMessage id='forgotPassword.header' />
          </h1>
          <p>
            <FormattedMessage id='forgotPassword.subHeader' />
          </p>
          <label htmlFor='email'>
            <FormattedMessage id='userProfile.email' />
          </label>
          <input
            type='email'
            className={`${errors.email ? 'invalid' : ''} ${
              !errors.email && touchedFields.email ? 'valid' : ''
            }`}
            {...register('email', { required: true })}
          />
          <input
            type='submit'
            value={intl.formatMessage({ id: 'forgotPassword.resetPassword' })}
          />

          {response && !response.ok && (
            <Error text={`${response.error.message}`} />
          )}

          {response && response.ok && (
            <Success
              text={intl.formatMessage({
                id: 'forgotPassword.emailSentSuccess',
              })}
            />
          )}
        </form>
      </Content>
    </SplitLayout>
  )
}

export default ForgotPassword
