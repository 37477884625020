import React from 'react'
import styled from 'styled-components'

const SuccessMsg = styled.div`
  color: #31c27c;
  margin: 1rem 0 0 0;
`

const Success = (props) => <SuccessMsg {...props}>{props.text}</SuccessMsg>

export default Success
